@import "./../Common.scss";

.login-controller {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
  align-items: center;
  height: 100vh;
  width: 100vw;

  .form {
    width: 320px;
    position: relative;
    border: 1px solid $borderColor;
    padding: 1.5rem 1.75rem;
    border-radius: $borderRadius;
    display: flex;
    flex-direction: column;
    height: fit-content;
    gap: 1rem;

    align-items: center;
    font-size: 1rem;

    .appLogo {
      position: relative;
      width: 75%;
      max-width: 250px;
      // max-width: unset;

      img {
        position: relative;
        width: 100%;
        max-height: unset;
      }
    }

    .inputs {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 100%;
      font-size: inherit;

      input {
        &:focus-within {
          outline: none;
        }
      }

      > input {
        font-size: inherit;

        position: relative;
        padding: 0.75rem 1.25rem;
        border: none;
        border-bottom: 2px solid $borderColor;
        width: -webkit-fill-available;
        background-color: white;
        transition-duration: $transitionDuration;

        &:focus-within {
          outline: none;
          background-color: whitesmoke;
          border-bottom: 2px solid $brandColor;
        }
      }

      .password-fields {
        display: flex;
        flex-direction: row;
        font-size: inherit;
        position: relative;
        border: none;
        width: -webkit-fill-available;
        background-color: white;
        transition-duration: $transitionDuration;
        border-bottom: 2px solid $borderColor;

        > input {
          font-size: inherit;

          position: relative;
          padding: 0.75rem 1.25rem;
          border: none;

          width: -webkit-fill-available;
          background-color: transparent;
        }

        .togglePasswordDisplay {
          margin: 0.25rem;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0.5rem 0.75rem;
          border: none;
          border-radius: 0.5rem;
          background-color: white;
          cursor: pointer;
          transition-duration: $transitionDuration;

          svg {
            font-size: 1rem;
          }

          &[data-active="true"] {
            background-color: rgb(230, 230, 230);
          }
        }

        &:focus-within {
          outline: none;
          background-color: whitesmoke;
          border-bottom: 2px solid $brandColor;
        }
      }
    }

    .buttons {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        > hr {
          padding: 0;
          margin: 0;
          height: 0;
          width: 75%;
          align-self: center;
          border: none;
          border-bottom: 1px solid $brandColor;
          opacity: 0.5;
  
        }

        button {
            border: none;
            border-radius: $borderRadius;
            padding: 0.75rem 1rem;
            text-align: center;
            font-size: inherit;
            background-color: white;
            color: $brandColor;
            transition-duration: $transitionDuration;
            cursor: pointer;

            &:hover {
                background-color: whitesmoke;
            }

            &.login {
                background-color: $brandColor;
                color: $onBrandColor;
                opacity: 0.9;
                &:hover {
                    opacity: 1;
                }
             
            }
        }
    }
  }
}
