
.header-widget {
    background-color: whitesmoke;
    padding: 1rem;
  
    width: auto;
    display: flex;
    position: relative;
    align-items: center;

    .actions {
        margin-left: auto;
        margin-right: 0;
        display: flex;
        gap: 0.5rem;
    }
}