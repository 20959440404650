@import "./../../variables.scss";

.HeaderCircleButtonWidget {
   // border-radius: $headerAvatarSize;
    width: $headerAvatarSize;
    height: $headerAvatarSize;

    padding: 0;
    border: none;
    border-radius: 100%;
    background-color: whitesmoke;
    cursor: pointer;

    // display: flex;
    // justify-content: center;
    // align-items: center;
    // overflow: hidden;
    transition-duration: $transitionDuration;

    &:hover {
       // opacity: 0.75;
        background-color: rgb(225, 225, 225);
    }

    display: flex;
    align-items: center;
    justify-content: center;


  
}