.CommonHeader {
  //padding: 0.25rem 1rem;
  background-color: white;
 
  z-index: 10;

  position: sticky;
  
  width: 100%;

  &.hide {
    top: 0;
  }

  > div {
    padding: 0.25rem 1rem;
  }

  //   display: flex;
  //   flex-direction: row;

  display: grid;
  grid-template-columns: min-content auto min-content;
  align-items: center;

  height: calc(2 * 0.25rem + 60px);
  width: 100vw;

  .menuArea {
    width: -webkit-fill-available;
  }

  .actions {
    position: relative;
    display: flex;
    gap: 0.5rem;
    width: fit-content;
    margin-left: auto;
    align-items: center;
  }

  @media only screen and (max-width: 767px) {
    /* Styles for small screens (mobile) */
    > div {
      padding: 0.5rem;
    }

    .actions {
      .login-button {
        display: none;
      }
    }
  }
}
