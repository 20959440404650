@import "./../../variables.scss";

.login-button {

    padding:$buttonPadding;
    border-radius: $borderRadius;
    color: $onBrandColor;
    border: none;
    background-color: $brandColor;
    cursor: pointer;
    height: fit-content;

}