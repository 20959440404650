
.side-widget {
    position: relative;
    height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    overflow: auto;


   
}