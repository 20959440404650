@import "./../../variables.scss";

.AvatarWidget {
    overflow: hidden;
    border-radius: 100%;
    //padding: 0.5rem;
    width: $headerAvatarSize;
    height: $headerAvatarSize;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: whitesmoke;

    img {
        width: 100%;
    }
}