@import "./future-theme.scss";

body {

    --WebBackgroundColor: $WebBackgroundColor;
    --panelBackgroundColor: $panelBackgroundColor;

    --panelBorderColor: $panelBorderColor;
    --textColor: $textColor;
    --textHighlightedColor: $textHighlightedColor;


    --buttonHoverBackground: $buttonHoverBackground;
    --buttonActiveBackground: $buttonActiveBackground;
    --buttonBackground: $buttonBackground;

    --transitionDuration: $transitionDuration;


}