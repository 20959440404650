@import './../../../variables.scss';

.AppDrawer {
    position: absolute;
    right: -100%;
    top: calc(100% + 15px);
    //top: calc(100% + 20px);
    //transform: translateY(-50%);
    display: none;
    padding: 1rem;
    height: min-content;
    max-height: 75vh;
    //min-width: 260px;
    max-width: 320px;
    background-color: white;
    border-radius: $borderRadius;
    border: 1px solid $borderColor;
    width: min-content;

    &.show {
        display: block;
       // position: absolute;
        right: 15px;
      
    }

    .AppList {
        width: 100%;
        display: grid;
        grid-template-columns: auto auto auto;
        gap: 1rem;
       
        transition-duration: $transitionDuration;
    }
}