@import "./theme.scss";

.dashboard {
  
    height: 100vh;
    background-color: $WebBackgroundColor;

    .header-widget {
        border-radius: .5rem;
        padding: 0.5rem;
        background-color: $panelBackgroundColor;
        border: 1px solid $panelBorderColor;
    }

    .AppDrawer {
        border-radius: .5rem;


        &.show {
            right: 0;
        }
    }


    > .viewer {
        display: flex;
        gap: 1rem;
        flex-direction: column;
        padding: 1rem;
        height: -webkit-fill-available;

     
    
        .row-display {
            
            display: grid;
            grid-template-columns: 250px auto;
            gap: 1rem;
            height: -webkit-fill-available;
            overflow: hidden;
            > div {
                background-color: $panelBackgroundColor;
                border: 1px solid $panelBorderColor;

                border-radius: .5rem;
                height: -webkit-fill-available;

                transition-duration:  $transitionDuration;

                overflow: auto;

                // &[is-loading='1'] {
                //     background-color: $panelBackgroundLoading !important;
                // }
            }
        }
    }

 
}