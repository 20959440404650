$WebBackgroundColor: whitesmoke;
$panelBackgroundColor: white;
$panelBackgroundLoading: rgb(247, 247, 247);

$panelBorderColor: white;

$textColor: rgb(75, 75, 75);
$textHighlightedColor: grey;


$buttonHoverBackground: whitesmoke;
$buttonActiveBackground: whitesmoke;
$buttonBackground: transparent;

$transitionDuration: 300ms;

