@import './../../../variables.scss';

.WebinifyAppPanelButton {
    background-color: white;

    &:hover {
        opacity: 1;
        background-color: whitesmoke;

    }
    opacity: 0.75;
    position: relative;
    //  width: min-content;
    // height: 50px;
    // padding: 0.5rem;
    // width: fit-content;
    width: 80px;
    height: 80px;
    padding: 5px;

    overflow: hidden;
    border-radius: $borderRadius;
    border: none;
    background-color: none;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .AppIcon {
        position: relative;
        display: flex;
        width: 100%;
        height: 50px;
        padding: 0.5rem;
      
        overflow: hidden;
        align-items: center;
        justify-content: center;
        img {
            width: 100%;
        }
    }

    .AppName {
        display: flex;
        align-items: center;
        padding: 0.25rem;
        p {
            padding: 0;
            margin: 0;
            font-size: 0.75rem;
            font-weight: bolder;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}