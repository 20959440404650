@import './../../variables.scss';

.HeaderCircleButtonWidget {
    background-color: white;
    position: relative;


    img {
        width: 100%;
    }
   
    svg {
        color: $brandColor ;
        font-size: 1.75rem;
    }
}